import React, { Suspense, lazy } from "react"
import "./assets/App.css"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { AuthProvider } from "./context/AuthContext"
import { Helmet, HelmetProvider } from "react-helmet-async"
import Header from "./pages/Landingpage/Header"
import ContactButton from "./components/ContactButton"
import PrivateRoute from "./components/PrivateRoute"

// Lazy load components
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"))
const Hero = lazy(() => import("./pages/Landingpage/Hero"))
const Register = lazy(() => import("./pages/Sign/Register"))
const Login = lazy(() => import("./pages/Sign/Login"))
const ForgotPassword = lazy(() => import("./pages/Sign/ForgotPassword"))
const Pricing = lazy(() => import("./components/Pricing"))
const Examples = lazy(() => import("./pages/Landingpage/Examples"))
const CalltoAction = lazy(() => import("./pages/Landingpage/CalltoAction"))
const Popup = lazy(() => import("./pages/Sign/Popup"))
const FAQs = lazy(() => import("./components/Faq"))
const Docs = lazy(() => import("./pages/API/Docs"))
const ComfyUI = lazy(() => import("./pages/API/ComfyUI"))
const Admin = lazy(() => import("./pages/Dashboard/Admin"))
const AdminDashboard = lazy(() => import("./pages/Dashboard/AdminDashboard"))
const Success = lazy(() => import("./pages/Dashboard/Success"))
const Features = lazy(() => import("./pages/Landingpage/Features"))
const Footer = lazy(() => import("./pages/Landingpage/Footer"))
const Billing = lazy(() => import("./pages/Other/Billing"))
const Support = lazy(() => import("./pages/Other/Support"))
const ImageConverter = lazy(() => import("./pages/Tools/ImageConverter"))
const NotFound = lazy(() => import("./pages/Other/NotFound"))
const FluxUpscaler = lazy(() => import("./pages/Tools/FluxUpscaler"))
// Loading fallback component
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-300"></div>
  </div>
)

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <BrowserRouter>
          <AppContent />
          <Route exact path="/">
            <ContactButton />
          </Route>
        </BrowserRouter>
      </AuthProvider>
    </HelmetProvider>
  )
}

function AppContent() {
  return (
    <div className="flex flex-col min-h-screen bg-bg-color">
      <Suspense fallback={<LoadingFallback />}>
        <Switch>
          <Route exact path="/login">
            <Header />
            <Login />
          </Route>
          <Route exact path="/register">
            <Header />
            <Register />
          </Route>
          <Route exact path="/forgot-password">
            <Header />
            <ForgotPassword />
          </Route>
          <Route exact path="/">
            <Helmet>
              <title>Clarity AI - #1 AI Image Upscaler & Enhancer</title>
              <meta
                name="description"
                content="Upscale and enhance your images with the power of AI. Clarity AI is the #1 AI Image Upscaler & Enhancer with API support. Enlarge images and get HD quality."
              />
              <link rel="canonical" href="https://clarityai.co" />
            </Helmet>
            <Header />
            <Popup />
            <Hero />
            <Features />
            <Examples />
            <FAQs />
            <CalltoAction />
            <Footer />
          </Route>
          <Route exact path="/dashboard">
            <Header />
            <Helmet>
              <title>
                Clarity AI Dashboard - Upscale & Enhance your Images
              </title>
              <meta
                name="description"
                content="Access your Clarity AI dashboard to manage your account and settings. Utilize the leading AI technology for image upscaling and enhancement, all from one central location."
              />
              <link rel="canonical" href="https://clarityai.co/dashboard" />
            </Helmet>
            <Dashboard />
          </Route>
          <Route exact path="/api">
            <Helmet>
              <title>API Image Upscaler & Enhancer - Clarity AI</title>
              <meta
                name="description"
                content="API to upscale & enhance images. Business API to get high resolution images. Benefit from permanent output files, simple parameters, optimized presets, and fast prediction times."
              />
              <link rel="canonical" href="https://clarityai.co/api" />
            </Helmet>
            <Header />
            <Docs />
          </Route>
          <Route exact path="/comfyui">
            <Helmet>
              <title>ComfyUI Upscaler & Enhancer - Clarity AI Plugin</title>
              <meta
                name="description"
                content="The ComfyUI for Clarity AI. Enhance and upscale your images with ease using our AI-driven solutions, optimized for high resolution and perfect results."
              />
              <link rel="canonical" href="https://clarityai.co/comfyui" />
            </Helmet>
            <Header />
            <ComfyUI />
          </Route>
          <Route exact path="/pricing">
            <Helmet>
              <title>Pricing - Clarity AI Image Upscaler & Enhancer</title>
              <meta
                name="description"
                content="Explore Clarity AI's flexible pricing options for our advanced image upscaler and enhancer. Choose from subscription-based plans or one-off purchases to suit your needs."
              />
              <link rel="canonical" href="https://clarityai.co/pricing" />
            </Helmet>
            <Header />
            <Pricing />
          </Route>
          <Route exact path="/success">
            <Header />
            <Success />
          </Route>
          <Route exact path="/billing">
            <Header />
            <Helmet>
              <title>Billing - Clarity AI</title>
              <meta
                name="description"
                content="Manage your billing settings for Clarity AI. Change your plan, cancel your subscription, or get support."
              />
              <link rel="canonical" href="https://clarityai.co/billing" />
            </Helmet>
            <Billing />
          </Route>
          <Route exact path="/support">
            <Helmet>
              <title>Support - Clarity AI</title>
              <meta
                name="description"
                content="Get support for Clarity AI. Contact me for any issues or questions."
              />
              <link rel="canonical" href="https://clarityai.co/support" />
            </Helmet>
            <Header />
            <Support />
          </Route>
          <PrivateRoute exact path="/admin">
            <Header />
            <Admin />
          </PrivateRoute>
          <PrivateRoute exact path="/admin-dashboard">
            <Header />
            <AdminDashboard />
          </PrivateRoute>
          <Route exact path="/anime-image-upscaling">
            <Helmet>
              <title>
                Anime Image Upscaling - Clarity AI - #1 AI Anime Upscaler
              </title>
              <meta
                name="description"
                content="Upscale and enhance your anime images with Clarity AI. Our advanced AI technology preserves the unique style of anime while increasing resolution and detail."
              />
              <link
                rel="canonical"
                href="https://clarityai.co/anime-image-upscaling"
              />
            </Helmet>
            <Header />
            <Popup />
            <Hero keyword="anime-image-upscaling" />
            <Features keyword="anime-image-upscaling" />
            <Examples keyword="anime-image-upscaling" />
            <FAQs keyword="anime-image-upscaling" />
            <CalltoAction keyword="anime-image-upscaling" />
            <Footer />
          </Route>
          <Route exact path="/tools/high-resolution-image-converter">
            <Helmet>
              <title>
                High Resolution Image Converter - HD 4k Image Converter
              </title>
              <meta
                name="description"
                content="Easily increase image resolution for impressive results. Convert low-resolution images into high-definition works of art with our AI-driven tool."
              />
              <link
                rel="canonical"
                href="https://clarityai.co/tools/high-resolution-image-converter"
              />
            </Helmet>
            <Header />
            <ImageConverter />
            <Footer />
          </Route>
          <Route exact path="/flux-upscaler">
            <Helmet>
              <title>
                Flux Upscaler - AI Image Upscaling with Flux Model | Clarity AI
              </title>
              <meta
                name="description"
                content="Transform your images with the Flux AI Upscaler. Compatible with Flux.1-dev-controlnet-upscaler, ComfyUI and other Flux workflows. The best upscaler for Flux available online."
              />
              <link rel="canonical" href="https://clarityai.co/flux-upscaler" />
            </Helmet>

            <Header />
            <FluxUpscaler />
            <Footer />
          </Route>
          <Route path="*">
            <Header />
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </div>
  )
}

export default App
